import axios from "axios";

const URL = "191.243.197.179:3003";


const api = axios.create({
    baseURL: URL,
    auth: {
        username: "PredFacil",
        password: "159357"
    }    
});

export default api;